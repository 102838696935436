export enum SATELLITE_DATA {
  FRAME_NUMBER = "frameNumber",
  PRODUCT_NAME = "productName",
  BROWSE = "browse",
  POLYGON = "polygon",
  TAGS = "tags",
  PROCESS_NAME = "processName",
  CACULATE_IMAGE_BOUNDS = "calculateImageBounds",
  FIRST_FRAME = "firstFrame",
  TRACK = "track",
}

export enum SENTINEL_SEARCH_OPTIONS {
  BEAM_MODE = "beamMode",
  POLARIZATION = "polarization",
  FLIGHT_DIRECTION = "flightDirection",
}

export enum BEAM_MODE {
  SM = "SM",
  IW = "IW",
  EW = "EW",
}

export enum POLARIZATION {
  VV = "VV",
  VV_VH = "VV + VH",
  HH = "HH",
  HH_HV = "HH + HV",
}

export enum FLIGHT_DIRECTION {
  ASCENDING = "ASCENDING",
  DESCENDING = "DESCENDING",
}
