import {
  BEAM_MODE,
  FLIGHT_DIRECTION,
  POLARIZATION,
  SATELLITES,
} from "../enums";

export const satellites = [
  {
    title: SATELLITES.SENTINEL_1A,
    status: true,
  },
  {
    title: SATELLITES.DATASET,
    status: true,
  },
  {
    title: SATELLITES.UMBRA,
    status: true,
  },
];

export const beamMode = [
  {
    title: "SM - Stripmap Mode",
    subTitle: "3.5m x 3.5m",
    tag: BEAM_MODE.SM,
  },
  {
    title: "IW - Interferometric Wide Swath Mode",
    subTitle: "10m x 10m",
    tag: BEAM_MODE.IW,
  },
  {
    title: "EW - Extra Wide Swath Mode",
    subTitle: "40m x 40m",
    tag: BEAM_MODE.EW,
  },
];

export const polarization = [
  { title: POLARIZATION.VV, tag: POLARIZATION.VV },
  { title: POLARIZATION.VV_VH, tag: POLARIZATION.VV_VH },
  { title: POLARIZATION.HH, tag: POLARIZATION.HH },
  { title: POLARIZATION.HH_HV, tag: POLARIZATION.HH_HV },
];

export const flightDirection = [
  { title: FLIGHT_DIRECTION.ASCENDING, tag: FLIGHT_DIRECTION.ASCENDING },
  { title: FLIGHT_DIRECTION.DESCENDING, tag: FLIGHT_DIRECTION.DESCENDING },
];

export const dataCollections = {
  beamMode,
  polarization,
  flightDirection,
  startDate: "",
  endDate: "",
};
