import React from "react";

import { Analyse, Bridge, Map, Ship, Water } from "@repo/ui/icons";
import { IoColorFillOutline } from "react-icons/io5";
import { WiEarthquake } from "react-icons/wi";

export const datasetItems = [
  {
    text: "SAR",
    query: "sar",
    icon: <Map />,
    style: "text-palette-red",
  },
  {
    text: "InSAR",
    query: "insar",
    icon: <Analyse />,
    style: "text-palette-orange",
  },
  {
    text: "Ship Detection",
    query: "ship",
    icon: <Ship />,
    style: "text-palette-yellow",
  },
  {
    text: "Bridge Detection",
    query: "bridge",
    icon: <Bridge />,
    style: "text-palette-green",
  },
  {
    text: "Water Body Detection",
    query: "water",
    icon: <Water />,
    style: "text-palette-teal",
  },
  {
    text: "Earthquake Detection",
    query: "earthquake",
    icon: <WiEarthquake size={20} role="EarthquakeDetection" />,
    style: "text-palette-cyan",
  },
  {
    text: "Oilspill Detection",
    query: "oilspill",
    icon: <IoColorFillOutline size={20} role="Oilspill Detection" />,
    style: "text-violet-700",
  },
];
