export const PLATFORM_ROUTES = {
  REQUEST_FIELDS: { href: "/request-fields", name: "request-fields" },
  SEARCHED_DATA: { href: "/searched-data", name: "searched-data" },
  QUAKES: { href: "/quakes", name: "quakes" },
  HISTORY: {
    WORK_LIST: { href: "/history/work-list", name: "work-list" },
    MY_DATA_LIST: { href: "/history/my-data-list", name: "my-data-list" },
  },
  DATASET: { href: "/dataset", name: "dataset" },
  OBSERVER: { href: "/observer", name: "observer" },
};
