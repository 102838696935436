interface SatelliteProps {
  width?: number;
  height?: number;
}

export default function Satellite({ width = 18, height = 18 }: SatelliteProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_82_941)">
        <path
          d="M2.78014 4.71964L4.71964 2.78014C4.86028 2.63953 5.05101 2.56055 5.24989 2.56055C5.44876 2.56055 5.63949 2.63953 5.78014 2.78014L9.96964 6.96964C10.1102 7.11028 10.1892 7.30101 10.1892 7.49989C10.1892 7.69876 10.1102 7.88949 9.96964 8.03014L8.03014 9.96964C7.88949 10.1102 7.69876 10.1892 7.49989 10.1892C7.30101 10.1892 7.11028 10.1102 6.96964 9.96964L2.78014 5.78014C2.63953 5.63949 2.56055 5.44876 2.56055 5.24989C2.56055 5.05101 2.63953 4.86028 2.78014 4.71964Z"
          stroke="currentColor"
          strokeWidth="1"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.5 7.5L2.25 9.75L4.5 12L6.75 9.75"
          stroke="currentColor"
          strokeWidth="1"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.5 4.5L9.75 2.25L12 4.5L9.75 6.75"
          stroke="currentColor"
          strokeWidth="1"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9 9L10.125 10.125"
          stroke="currentColor"
          strokeWidth="1"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.875 12.75C11.3723 12.75 11.8492 12.5525 12.2008 12.2008C12.5525 11.8492 12.75 11.3723 12.75 10.875"
          stroke="currentColor"
          strokeWidth="1"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.25 15.75C12.4435 15.75 13.5881 15.2759 14.432 14.432C15.2759 13.5881 15.75 12.4435 15.75 11.25"
          stroke="currentColor"
          strokeWidth="1"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_82_941">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
