export default function AllChecked() {
  return (
    <svg
      width="100"
      height="100"
      viewBox="0 0 122 122"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Group 427319175" filter="url(#filter0_d_42_4630)">
        <circle id="Ellipse 24" cx="61" cy="57" r="41" fill="#13DEB9" />
        <path
          id="Vector"
          d="M85.9119 40.0838C87.3627 41.5289 87.3627 43.8757 85.9119 45.3208L56.201 74.9162C54.7502 76.3613 52.3943 76.3613 50.9435 74.9162L36.088 60.1185C34.6373 58.6734 34.6373 56.3266 36.088 54.8815C37.5388 53.4364 39.8948 53.4364 41.3455 54.8815L53.5781 67.0549L80.6661 40.0838C82.1168 38.6387 84.4728 38.6387 85.9236 40.0838H85.9119Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_42_4630"
          x="0"
          y="0"
          width="122"
          height="122"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="10" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.8 0 0 0 0 0.6 0 0 0 0.2 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_42_4630"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_42_4630"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}
