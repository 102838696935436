export const filters = [
  {
    title: "In Progress",
    bg: "bg-palette-cyan",
  },
  {
    title: "Finished",
    bg: "bg-palette-green",
  },
  {
    title: "Failed",
    bg: "bg-palette-red",
  },
  {
    title: "Canceled",
    bg: "bg-gray-400",
  },
];
