export const algorithms = [
  {
    title: "D-InSAR",
    status: true,
  },
  {
    title: "PS-InSAR",
    status: false,
  },
  // {
  //   title: "ComSAR",
  //   status: false,
  // },
] as const;

export const processSteps = {
  "D-InSAR": {
    phase: {
      none: {
        contents: [
          { status: false, text: "Pre Image is Not Available." },
          { status: false, text: "Post Image is Not Available." },
          {
            status: false,
            text: "There is insufficient overlap in the images.",
          },
        ],
      },
      pre: {
        contents: [
          { status: true, text: "Pre Image is Available." },
          { status: false, text: "Post Image is Not Available." },
          {
            status: false,
            text: "There is insufficient overlap in the images.",
          },
        ],
      },
      post: {
        contents: [
          { status: true, text: "Pre Image is Available." },
          { status: true, text: "Post Image is Available." },
          {
            status: false,
            text: "There is insufficient overlap in the images.",
          },
        ],
      },
      available: {
        contents: [
          { status: true, text: "Pre Image is Available." },
          { status: true, text: "Post Image is Available." },
          { status: true, text: "There is sufficient overlap in the images." },
        ],
      },
      imageExceeded: {
        contents: [
          { status: true, text: "Pre Image is Available." },
          { status: true, text: "Post Image is Available." },
          { status: false, text: "There are more than 2 images." },
        ],
      },
    },
  },
  "PS-InSAR": {
    phase: {
      none: {
        contents: [
          { status: false, text: "Pre Image is Not Available." },
          { status: false, text: "Post Image is Not Available." },
          {
            status: false,
            text: "There is insufficient overlap in the images.",
          },
        ],
      },
      pre: {
        contents: [
          { status: true, text: "Pre Image is Available." },
          { status: false, text: "Post Image is Not Available." },
          {
            status: false,
            text: "There is insufficient overlap in the images.",
          },
        ],
      },
      post: {
        contents: [
          { status: true, text: "Pre Image is Available." },
          { status: true, text: "Post Image is Available." },
          {
            status: false,
            text: "There is insufficient overlap in the images.",
          },
        ],
      },
      available: {
        contents: [
          { status: true, text: "Pre Image is Available." },
          { status: true, text: "Post Image is Available." },
          {
            status: true,
            text: "There is sufficient overlap in the images.",
          },
        ],
      },
      imageExceeded: {
        contents: [
          { status: true, text: "Pre Image is Available." },
          { status: true, text: "Post Image is Available." },
          { status: false, text: "There are more than 2 images." },
        ],
      },
    },
  },
};
