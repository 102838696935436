const BASE_URL = process.env.NEXT_PUBLIC_WORK_SPACE_BASE_PATH || "/";
const SHOW_PROCESS_BASE = "/show-process";
const PAYMENT_BASE = "/payment";

export const WORK_SPACE_ROUTES = {
  MY_DATA: {
    href: `/my-data`,
    name: `my-data`,
  },
  UPLOADED: {
    href: `/uploaded`,
    name: "uploaded",
  },
  SHOW_PROCESS: {
    CURRENT_PROCESSING: {
      href: `${SHOW_PROCESS_BASE}/current-processing`,
      name: "current-processing",
    },
    PROCESSING_HISTORY: {
      href: `${SHOW_PROCESS_BASE}/processing-history`,
      name: "processing-history",
    },
  },
  PAYMENT: {
    PURCHASE_HISTORY: {
      href: `${PAYMENT_BASE}/purchase-history`,
      name: "purchase-history",
    },
    REFUND_HISTORY: {
      href: `${PAYMENT_BASE}/refund-history`,
      name: "refund-history",
    },
  },
};
