// TODO: enum으로 변경해서 복잡도 줄이기

export const TOAST_CONSTANTS = {
  search: {
    content: "Draw Polygon to Search Data!",
    style: {
      borderRadius: "5px",
      width: "fit-content",
      padding: "5px 10px",
      background: "white",
      color: "black",
    },
  },

  success: {
    content: "Data found for the selected area.",
    style: {
      borderRadius: "5px",
      width: "fit-content",
      padding: "5px 10px",
      background: "white",
      color: "black",
    },
  },
  error: {
    content: "No data found for the selected area.",
    style: {
      borderRadius: "5px",
      width: "340px",
      padding: "2px 5px",
      background: "white",
      color: "black",
    },
  },
};
