// icons/index.ts
export interface IconProps {
  width?: number;
  height?: number;
}

export { default as AccessPoint } from "./AccessPoint";
export { default as ActiveHeartBeat } from "./ActiveHeartBeat";
export { default as AlarmBell } from "./AlarmBell";
export { default as AlertCircle } from "./AlertCircle";
export { default as AllChecked } from "./AllChecked";
export { default as Analyse } from "./Analyse";
export { default as AnalyticDevice } from "./AnalyticDevice";
export { default as Bridge } from "./Bridge";
export { default as Bricks } from "./Bricks";
export { default as CalendarCheck } from "./CalendarCheck";
export { default as CalendarNumber } from "./CalendarNumber";
export { default as Check } from "./Check";
export { default as Clipboard } from "./Clipboard";
export { default as Close } from "./Close";
export { default as CloudUpload } from "./CloudUpload";
export { default as CPU } from "./CPU";
export { default as CreditCard } from "./CreditCard";
export { default as CreditCardPay } from "./CreditCardPay";
export { default as CreditCardRefund } from "./CreditCardRefund";
export { default as Database } from "./Database";
export { default as DesktopAnalytics } from "./DesktopAnalytics";
export { default as Download } from "./Download";
export { default as File } from "./File";
export { default as FileDatabase } from "./FileDatabase";
export { default as Files } from "./Files";
export { default as Filter } from "./Filter";
export { default as Folder } from "./Folder";
export { default as History } from "./History";
export { default as HourGlassEmpty } from "./HourGlassEmpty";
export { default as JetPack } from "./JetPack";
export { default as LeftChevron } from "./LeftChevron";
export { default as Map } from "./Map";
export { default as MapSearch } from "./MapSearch";
export { default as NormalClose } from "./NormalClose";
export { default as Pause } from "./Pause";
export { default as Photo } from "./Photo";
export { default as Planet } from "./Planet";
export { default as PrevArrow } from "./PrevArrow";
export { default as ReceiptDollar } from "./ReceiptDollar";
export { default as ReportAnalytics } from "./ReportAnalytics";
export { default as RightChevron } from "./RightChevron";
export { default as Rocket } from "./Rocket";
export { default as Satellite } from "./Satellite";
export { default as Ship } from "./Ship";
export { default as ShoppingCart } from "./ShoppingCart";
export { default as Trash } from "./Trash";
export { default as Upload } from "./Upload";
export { default as Water } from "./Water";
export { default as World } from "./World";
