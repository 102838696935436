import { AnalyticDevice, Satellite, World } from "@repo/ui/icons";
import { PLATFORM_ROUTES } from "../constants/platform-routes";

const baseSidebarItems = [
  {
    icon: <World />,
    title: "Home",
    href: PLATFORM_ROUTES.REQUEST_FIELDS.href,
  },
  // {
  //   icon: <Folder />,
  //   title: "History",
  //   href: PLATFORM_ROUTES.HISTORY.WORK_LIST.href,
  // },
  {
    icon: <Satellite width={28} height={28} />,
    title: "Dataset",
    href: PLATFORM_ROUTES.DATASET.href,
  },
];

const workspaceURL =
  process.env.NODE_ENV === "production"
    ? "https://sardip.lumir.space/work-space/my-data"
    : "http://192.168.10.32:3101/work-space";

export const bottomSidebarItems = [
  {
    icon: <AnalyticDevice />,
    title: "Web-GIS Workspace",
    href: workspaceURL,
    // disabled: process.env.NODE_ENV === "production",
  },
];

// Conditionally add the "Observer" item if in development mode
// const devSidebarItems = [
//   ...baseSidebarItems,
//   {
//     icon: <AnalyticDevice />,
//     title: "Observer",
//     href: PLATFORM_ROUTES.OBSERVER.href,
//   },
// ];
export const sidebarItems = /*
  process.env.NODE_ENV === "development" ? devSidebarItems : */ baseSidebarItems;
