import {
  ActiveHeartBeat,
  CalendarCheck,
  Clipboard,
  CloudUpload,
  CreditCardPay,
  CreditCardRefund,
  File,
  FileDatabase,
  Files,
  History,
  HourGlassEmpty,
  MapSearch,
} from "@repo/ui/icons";
import { WiEarthquake } from "react-icons/wi";
import { WORK_SPACE_ROUTES } from "./work-space-routes";
import { PLATFORM_ROUTES } from "./platform-routes";

export interface TabItem {
  icon?: JSX.Element;
  title: string;
  url?: string;
  type?: string;
  disabled?: boolean;
}

// ? 홈페이지 탭
export const homeTabs: TabItem[] = [
  {
    icon: <MapSearch />,
    title: "Request Fields",
    url: PLATFORM_ROUTES.REQUEST_FIELDS.href,
    type: PLATFORM_ROUTES.REQUEST_FIELDS.name,
  },
  {
    icon: <Files />,
    title: "Searched Data",
    url: PLATFORM_ROUTES.SEARCHED_DATA.href,
    type: PLATFORM_ROUTES.SEARCHED_DATA.name,
  },
  // {
  //   icon: <ActiveHeartBeat />,
  //   title: "Quakes",
  //   url: PLATFORM_ROUTES.QUAKES.href,
  //   type: PLATFORM_ROUTES.QUAKES.name,
  // },
];

// ? 히스토리 페이지 탭
export const historyTabs: TabItem[] = [
  {
    icon: <Clipboard />,
    title: "Work List",
    url: PLATFORM_ROUTES.HISTORY.WORK_LIST.href,
    type: PLATFORM_ROUTES.HISTORY.WORK_LIST.name,
  },
  {
    icon: <Files />,
    title: "My Data List",
    url: PLATFORM_ROUTES.HISTORY.MY_DATA_LIST.href,
    type: PLATFORM_ROUTES.HISTORY.MY_DATA_LIST.name,
    // disabled: true,
  },
];

export const notificationTabs: Pick<TabItem, "title">[] = [
  { title: "All" },
  { title: "Subscribe" },
];

// ? 두 번째 사이드바의 푸터 탭
export const selectionSidebarTabs: TabItem[] = [
  {
    icon: <File />,
    title: "Image Info.",
    type: "selection-sidebar-tabs",
  },
  {
    icon: <Files />,
    title: "Related Files",
    type: "selection-sidebar-tabs",
    // disabled: true,
  },
  {
    icon: <CalendarCheck />,
    title: "Process",
    type: "selection-sidebar-tabs",
  },
];

// ? 분석 페이지 탭
export const analyticsTabs: TabItem[] = [
  {
    icon: <WiEarthquake size={24} />,
    title: "Earthquake",
  },
];

export const workspaceTabs: TabItem[] = [
  {
    icon: <FileDatabase />,
    title: "My Data",
    url: WORK_SPACE_ROUTES.MY_DATA.href,
    type: WORK_SPACE_ROUTES.MY_DATA.name,
  },
  {
    icon: <CloudUpload />,
    title: "Uploaded Data",
    url: WORK_SPACE_ROUTES.UPLOADED.href,
    type: WORK_SPACE_ROUTES.UPLOADED.name,
  },
];

export const showProcessTabs: TabItem[] = [
  {
    icon: <HourGlassEmpty />,
    title: "Current Processing",
    url: WORK_SPACE_ROUTES.SHOW_PROCESS.CURRENT_PROCESSING.href,
    type: WORK_SPACE_ROUTES.SHOW_PROCESS.CURRENT_PROCESSING.name,
  },
  {
    icon: <History />,
    title: "Processing History",
    url: WORK_SPACE_ROUTES.SHOW_PROCESS.PROCESSING_HISTORY.href,
    type: WORK_SPACE_ROUTES.SHOW_PROCESS.PROCESSING_HISTORY.name,
  },
];

export const paymentTabs: TabItem[] = [
  {
    icon: <CreditCardPay />,
    title: "Purchase History",
    url: WORK_SPACE_ROUTES.PAYMENT.PURCHASE_HISTORY.href,
    type: WORK_SPACE_ROUTES.PAYMENT.PURCHASE_HISTORY.name,
  },
  {
    icon: <CreditCardRefund />,
    title: "Refund History",
    url: WORK_SPACE_ROUTES.PAYMENT.REFUND_HISTORY.href,
    type: WORK_SPACE_ROUTES.PAYMENT.REFUND_HISTORY.name,
  },
];
