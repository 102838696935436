import * as L from "leaflet";

export const MAP_PARAMS = {
  center: [36, 127.5] as L.LatLngExpression,
  zoom: 6,
  zoomControl: false,
};

export interface MapTiles {
  url: string;
  options: {
    subdomains?: string[];
  };
  layerName:
    | "google_satellite"
    | "leaflet_osm"
    | "google_hybrid"
    | "google_hybrid_english";
  title: string;
}

const googleSatellite: MapTiles = {
  url: "https://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}&hl=en",
  options: {
    subdomains: ["mt0", "mt1", "mt2", "mt3"],
  },
  layerName: "google_hybrid_english",
  title: "satellite",
};

const leafletOsm: MapTiles = {
  url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
  options: {},
  layerName: "leaflet_osm",
  title: "basic",
};

export const MAP_TILES: MapTiles[] = [googleSatellite, leafletOsm];

// MAP_TILES 배열의 각 항목의 layerName 속성값을 추출하여 유니언 타입을 생성합니다.
type ExtractLayerName<T> = T extends { layerName: infer LN } ? LN : never;
export type MAP_TILES_TITLE = ExtractLayerName<(typeof MAP_TILES)[number]>;
