import { IconProps } from "./index";

export default function AlarmBell({ width = 14, height = 14 }: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 14 14"
      fill="none"
    >
      <g clipPath="url(#clip0_42_2421)">
        <path
          d="M8.30349 11.0833C8.80807 11.0833 9.07465 11.6807 8.73807 12.0563C8.51942 12.301 8.25153 12.4967 7.95195 12.6306C7.65237 12.7645 7.32788 12.8336 6.99974 12.8333C6.6716 12.8336 6.3471 12.7645 6.04753 12.6306C5.74795 12.4967 5.48005 12.301 5.2614 12.0563C4.9394 11.697 5.16924 11.1353 5.63124 11.0874L5.6954 11.0839L8.30349 11.0833Z"
          fill="currentColor"
        />
        <path
          d="M7.00013 1.16666C7.7923 1.16666 8.46197 1.69341 8.67722 2.41557L8.70405 2.51532L8.70872 2.54041C9.35178 2.90318 9.89975 3.41313 10.3078 4.02849C10.7158 4.64385 10.9722 5.3471 11.056 6.08066L11.0724 6.24807L11.0835 6.41666V8.12641L11.0957 8.20574C11.1756 8.63555 11.4134 9.01991 11.7625 9.28316L11.8599 9.35141L11.9544 9.40916C12.456 9.69324 12.281 10.4393 11.7345 10.4965L11.6668 10.5H2.33347C1.7338 10.5 1.52438 9.70432 2.04588 9.40916C2.26815 9.28337 2.46019 9.11047 2.60853 8.90257C2.75687 8.69468 2.8579 8.45683 2.90455 8.20574L2.9168 8.12232L2.91738 6.38982C2.95295 5.62792 3.17473 4.88635 3.5633 4.23001C3.95187 3.57368 4.4954 3.02259 5.1463 2.62499L5.29097 2.53982L5.2968 2.51474C5.37931 2.16592 5.56685 1.85081 5.8341 1.61194C6.10135 1.37306 6.43544 1.22192 6.7913 1.17891L6.89747 1.16899L7.00013 1.16666Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_42_2421">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
